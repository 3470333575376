<template>
  <div class="csr">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/csr/header-csr.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>Being good is important to us.</h1>
        <p>
          We conduct our business in a socially responsible manner, encouraging equality and respect
          between all people, while supporting social initiatives close to our hearts.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <h3>Doing the right thing</h3>
        <div class="csr-box d-flex justify-content-between align-items-center">
          <div class="csr-col">
            <h4>CSR is an integral part of our operation.</h4>
            <p>
              We pledge to always do the right thing in relation to our customers, employees,
              customers, suppliers, the environment and wider society. The following principles help
              guide us to make informed and ethical decisions to ensure that we do the right thing,
              wherever possible.
            </p>
          </div>

          <div class="csr-col">
            <h4>Comstream is a proud sponsor of</h4>
            <p>
              <a
                rel="nofollow"
                target="_blank"
                href="https://www.nzik.bg/lechebni-zavedeniya/meditsinski-tsentrove/meditsinski-tsentar-za-simp-po-belodrobni-bolesti/"
                >Alexandrovska University Hospital</a
              >
              for Specialized care for pulmonary diseases in Sofia, Bulgaria
            </p>
            <p>
              <a rel="nofollow" target="_blank" href="https://www.ou16.bg/"
                >6th Primary School Rajko Jinzifov</a
              >, a public primary school in Sofia, Bulgaria
            </p>
            <p>
              <a rel="nofollow" target="_blank" href="https://www.svenskalag.se/karlskronahandboll"
                >Karlskrona Handboll</a
              >
              organizing youth sporting activities in Karlskrona, Sweden
            </p>
          </div>

          <div class="overlay"></div>
        </div>
      </div>
      <div class="csr-sm-boxes d-flex justify-content-between flex-wrap">
        <div class="box">
          <img src="img/icons/icons8-repository-100.png" alt="icon" />
          <h4>We Commit to Healthy Work Environment.</h4>
          <p>
            An intelligent, modern and supportive workplace with fair treatment and equal
            opportunities in everything we do.
          </p>
        </div>

        <div class="box">
          <img src="img/icons/icons8-trust-100.png" alt="icon" />
          <h4>We Contribute.</h4>
          <p>
            We do our part in supporting the communities, countries and industries that we operate
            in
          </p>
        </div>

        <div class="box">
          <img src="img/icons/icons8-waste-separation-100.png" alt="icon" />
          <h4>We Reduce Waste</h4>
          <p>
            We actively and regularly pursue ways to reduce our environmental footprint wherever we
            operate.
          </p>
        </div>

        <div class="box">
          <img src="img/icons/icons8-crowd-100.png" alt="icon" />
          <h4>We like diversity.</h4>
          <p>
            We believe that diversity should be seen as an asset, helping all employees to feel
            heard and respected.
          </p>
        </div>

        <div class="box">
          <img src="img/icons/icons8-user-groups-100.png" alt="icon" />
          <h4>We Value Employees.</h4>
          <p>
            We all share the responsibility to uphold а safe, supportive and progressive work
            environment.
          </p>
        </div>

        <div class="box">
          <img src="img/icons/icons8-trophy-100.png" alt="icon" />
          <h4>We Do Our Best.</h4>
          <p>
            We always act in the best interests of our employees, stakeholders, customers and
            employees.
          </p>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="
        background: url('img/homepage/partnership.jpg');
        background-size: cover;
        margin-bottom: 0;
        color: #000;
      "
    >
      <div class="viewport">
        <h3
          style="color: #383838; font-size: 38px; font-weight: 700; width: 70%; margin: 20px auto"
        >
          Find out more about our history, mission and values
        </h3>
        <router-link to="/about-us" class="btn btn-primary">Read more</router-link>
      </div>
      <div class="overlay" style="background: rgba(255, 255, 255, 0.4)"></div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Corporate Social Responsibility | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.csr {
  .content {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 38px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    .csr-box {
      position: relative;
      height: 482px;
      background: url('/img/csr/csr-bg.jpg');
      background-size: cover;
      .csr-col {
        width: 40%;
        color: #fff;
        z-index: 1;
        padding: 0 50px;
        h4 {
          font-size: 28px;
          font-weight: 700;
          margin: 0 0 30px 0;
        }
        p {
          font-size: 18px;
          a {
            color: #fff;
            text-decoration: none;
            padding: 5px;
            background: #a93224;
          }
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        z-index: 0;
      }
    }
  }
  .csr-sm-boxes {
    .box {
      width: 32%;
      text-align: center;
      margin-bottom: 30px;
      h4 {
        font-size: 21px;
        color: #000;
        font-weight: 600;
        margin-bottom: 0px;
        height: 55px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .csr {
    .content {
      h3 {
        font-size: 2rem;
      }
      .csr-box {
        display: block !important;
        height: auto;
        .csr-col {
          width: 100%;
          position: relative;
          padding: 20px 12px;
        }
      }
    }
    .csr-sm-boxes {
      display: block !important;
      .box {
        width: 100%;
      }
    }
  }
}
</style>
